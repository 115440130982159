<template>
  <div v-if="!mounted">En cours de chargement...</div>
  <div v-else style="width: 100%; padding: 30px;">
    <h1 class="font-weight-medium" style="font-size: 20px;">Étape 4 - Saisie des salariés</h1>

    <h3 class="mb-0" style="font-size: 24px; font-weight: 300;">Salariés de la structure :</h3>

    <GestionSalaries
      style="padding-bottom: 30px;"
      :context="{
        hasCodeFederal,
        ID_Structure: currentStructureId,
        ID_Saison: this.selectedSeason.id,
      }"
    />

    <hr style="padding-bottom: 30px;" />

    <ValidateForm @validated="submitForm" :showFieldObl="false">
      <div class="mt-4">
        <inputText
          type="number"
          step="0.1"
          v-model="SAL_EquivalentTempsPlein"
          :label="
            currentStructure.STR_Structure.LIST_StructureType.id === 1
              ? 'Nombre d\'équivalents temps plein dans mon club/section de club '
              : 'Nombre d\'équivalents temps plein dans ma structure'
          "
          :disabled="!hasCodeFederal || disabled"
          rules="max:3"
          :width="60"
        />
        <span class="caption font-italic">
          (exemple: 0.5 pour un salarié à mi-temps. Si la structure n'a pas de salarié, indiquez 0)
        </span>
      </div>

      <div class="mt-4">
        <selectInput
          label="Si la structure a des salariés, quel est l'organisme de la formation professionnelle"
          :items="allCollecteur"
          v-model="currentCollecteur"
          item_text="NomCollecteur"
          item_value="id"
          :required="SAL_EquivalentTempsPlein > 0 ? 'required' : ''"
          :rules="SAL_EquivalentTempsPlein > 0 ? 'required' : ''"
          :disabled="!hasCodeFederal || disabled"
          @onChange="collecteurChange"
        ></selectInput>
      </div>

      <div class="mt-4" style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 15px;">
        <v-btn
          type="submit"
          :loading="loading"
          class="btn btn-primary"
        >
          Enregistrer les informations de cette étape
        </v-btn>

        <v-btn
          type="button"
          :loading="loading"
          :disabled="!stepIsValid"
          class="btn btn-primary"
          @click="() => { this.$router.push({ name: 'affiliations__tableau_de_synthese' }) }"
          >Étape suivante</v-btn
        >
      </div>
    </ValidateForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import GestionSalaries from "@/components/Structure/GestionSalaries/Structure__GestionSalaries.vue";
import ValidateForm from "@/components/utils/form.vue";
import inputText from "@/components/utils/input.vue";
import selectInput from "@/components/utils/select.vue";
import { bus } from "@/main";

export default {
  components: { GestionSalaries, inputText, ValidateForm, selectInput },

  data: () => ({
    selectedSeason: null,
    mounted: false,
    affiliation: null,
    SAL_EquivalentTempsPlein: "",
    allCollecteur: [],
    structure: null,
    currentCollecteur: null,
    loading: false,

    stepIsValid: false,
  }),

  async created() {
    this.$emit("updateTitle", "Gestion des salariés");
    await this.getListTypesDocument();
    await this.getListCollecteurFormationProfessionnelle();

    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["saisons"]),

    disabled() {
      if (!this.affiliation) return false;
      return this.affiliation.ID_EtapeAffiliation == 3;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListTypesDocument",
      "getListCollecteurFormationProfessionnelle",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "updateAffiliationETPStructure",
      "updateAffiliationCollecteurFormationPro",
    ]),

    async setData() {
      console.log("this.currentStructure :>> ", this.currentStructure);
      this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
      this.structure = { ...this.currentStructure };
      this.affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });
      this.allCollecteur = this.getList.LIST_CollecteurFormationProfessionnelle;
      this.SAL_EquivalentTempsPlein = this.affiliation.SAL_EquivalentTempsPlein;

      if (this.affiliation.ID_CollecteurFormationProfessionnelle) {
        this.currentCollecteur = this.allCollecteur.find(
          (c) => c.id == this.affiliation.ID_CollecteurFormationProfessionnelle
        );
      }

      this.validateStep();
    },

    async submitForm() {
      this.loading = true;

      let collecteur = this.getList.LIST_CollecteurFormationProfessionnelle.find(
        (col) => col.id == this.currentCollecteur
      );

      try {
        await this.updateAffiliationETPStructure({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSeason.id,
          SAL_EquivalentTempsPlein: this.SAL_EquivalentTempsPlein,
        });

        if (collecteur) {
          await this.updateAffiliationCollecteurFormationPro({
            ID_Structure: this.currentStructureId,
            ID_Saison: this.selectedSeason.id,
            ID_CollecteurFormationProfessionnelle: collecteur.id,
          });
        }

        success_notification("La modification de l’étape est un succès, poursuivez votre réaffiliation en allant à l’étape suivante");
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }

      this.validateStep();
      this.loading = false;
    },

    async collecteurChange(collecteur_id) {
      this.currentCollecteur = collecteur_id;
    },

    async validateStep() {
      let affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      // nb hours sal need to be setted.
      if (affiliation.SAL_EquivalentTempsPlein == null) {
        this.stepIsValid = false;
        return false;
      }

      // if nb hours ===0 then collecteur formation pro isnt needed
      if (+affiliation.SAL_EquivalentTempsPlein == 0) {
        bus.$emit('onRefreshNavigation', true);
        this.stepIsValid = true;
        return true;
      } else {
        if (affiliation.ID_CollecteurFormationProfessionnelle) {
          bus.$emit('onRefreshNavigation', true);
          this.stepIsValid = true;
          return true;
        }
      }

      this.stepIsValid = false;
      return false;
    },
  },
};
</script>
