var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.mounted)?_c('div',[_vm._v("En cours de chargement...")]):_c('div',{staticStyle:{"width":"100%","padding":"30px"}},[_c('h1',{staticClass:"font-weight-medium",staticStyle:{"font-size":"20px"}},[_vm._v("Étape 4 - Saisie des salariés")]),_c('h3',{staticClass:"mb-0",staticStyle:{"font-size":"24px","font-weight":"300"}},[_vm._v("Salariés de la structure :")]),_c('GestionSalaries',{staticStyle:{"padding-bottom":"30px"},attrs:{"context":{
      hasCodeFederal: _vm.hasCodeFederal,
      ID_Structure: _vm.currentStructureId,
      ID_Saison: this.selectedSeason.id,
    }}}),_c('hr',{staticStyle:{"padding-bottom":"30px"}}),_c('ValidateForm',{attrs:{"showFieldObl":false},on:{"validated":_vm.submitForm}},[_c('div',{staticClass:"mt-4"},[_c('inputText',{attrs:{"type":"number","step":"0.1","label":_vm.currentStructure.STR_Structure.LIST_StructureType.id === 1
            ? 'Nombre d\'équivalents temps plein dans mon club/section de club '
            : 'Nombre d\'équivalents temps plein dans ma structure',"disabled":!_vm.hasCodeFederal || _vm.disabled,"rules":"max:3","width":60},model:{value:(_vm.SAL_EquivalentTempsPlein),callback:function ($$v) {_vm.SAL_EquivalentTempsPlein=$$v},expression:"SAL_EquivalentTempsPlein"}}),_c('span',{staticClass:"caption font-italic"},[_vm._v(" (exemple: 0.5 pour un salarié à mi-temps. Si la structure n'a pas de salarié, indiquez 0) ")])],1),_c('div',{staticClass:"mt-4"},[_c('selectInput',{attrs:{"label":"Si la structure a des salariés, quel est l'organisme de la formation professionnelle","items":_vm.allCollecteur,"item_text":"NomCollecteur","item_value":"id","required":_vm.SAL_EquivalentTempsPlein > 0 ? 'required' : '',"rules":_vm.SAL_EquivalentTempsPlein > 0 ? 'required' : '',"disabled":!_vm.hasCodeFederal || _vm.disabled},on:{"onChange":_vm.collecteurChange},model:{value:(_vm.currentCollecteur),callback:function ($$v) {_vm.currentCollecteur=$$v},expression:"currentCollecteur"}})],1),_c('div',{staticClass:"mt-4",staticStyle:{"display":"flex","flex-direction":"row","flex-wrap":"nowrap","gap":"15px"}},[_c('v-btn',{staticClass:"btn btn-primary",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v(" Enregistrer les informations de cette étape ")]),_c('v-btn',{staticClass:"btn btn-primary",attrs:{"type":"button","loading":_vm.loading,"disabled":!_vm.stepIsValid},on:{"click":function () { this$1.$router.push({ name: 'affiliations__tableau_de_synthese' }) }}},[_vm._v("Étape suivante")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }